<template>
  <v-app>
    <ess-main-toolbar :menu-items="menuItems"
                      is-admin />

    <v-main>
      <v-scroll-y-reverse-transition mode="out-in">
        <router-view />
      </v-scroll-y-reverse-transition>
    </v-main>

    <ess-snackbar />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    EssMainToolbar: () => import('@/components/core/EssMainToolbar.vue'),
    EssSnackbar: () => import('@/components/core/EssSnackbar.vue'),
  },

  data() {
    return {
      // menuItems: [
      //   {
      //     title: 'Dashboard',
      //     name: 'MainDashboard',
      //     icon: 'mdi-view-dashboard',
      //   },
      //   {
      //     title: 'User Management',
      //     icon: 'mdi-view-dashboard',
      //     expanded: true,
      //     children: [
      //       {
      //         title: 'Users',
      //         name: 'MainUserManagement',
      //         icon: 'mdi-account-multiple',
      //       },
      //     ],
      //   },
      //   {
      //     title: 'Settings',
      //     name: 'MainSettings',
      //     icon: 'mdi-cog',
      //   },
      // ],
    };
  },

  computed: {
    ...mapGetters({
      menuItems: 'session/userMenu',
    }),
  },
};
</script>

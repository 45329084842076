<template>
  <v-app>
    <v-main>
      <v-scroll-y-reverse-transition mode="out-in">
        <router-view />
      </v-scroll-y-reverse-transition>
    </v-main>

    <ess-snackbar />
  </v-app>
</template>

<script>
export default {
  components: {
    EssSnackbar: () => import('@/components/core/EssSnackbar.vue'),
  },
};
</script>
